export default function animateToggle() {
  function animateInMaxHeight(element) {
    function removeMaxHeight() {
      this.style.maxHeight = 'none';
      this.removeEventListener('transitionend', transitionEndCallback);
      delete element._transitionEnd;
    }

    let transitionEndCallback = removeMaxHeight.bind(element);
    element._transitionEnd = transitionEndCallback;

    element.style.maxHeight = `${element.scrollHeight}px`;
    element.addEventListener('transitionend', transitionEndCallback);
  }

  function animateOutMaxHeight(element) {
    element.style.maxHeight = `${element.scrollHeight}px`;
    element.offsetHeight;

    if (element._transitionEnd) {
      element.removeEventListener('transitionend', element._transitionEnd);
    }

    setTimeout(function () {
      element.style.maxHeight = '0px';
    }, 1);
  }

  const toggleTriggers = document.querySelectorAll(
    `[data-toggle=animatetoggle]`,
  );
  toggleTriggers.forEach((trigger) => {
    let toggle = document.querySelector(
      `[aria-labelledby=${trigger.getAttribute('id')}]`,
    );

    if (toggle.classList.contains('hidden')) {
      toggle.style.maxHeight = `0px`;
      toggle.classList.remove('hidden');
    }
    toggle.classList.add('overflow-hidden');
    toggle.style.transition = `max-height 400ms`;

    // remove overflow hidden on already opened item
    if (trigger.getAttribute('aria-expanded') == 'true') {
      toggle.classList.remove('overflow-hidden');
    }

    function openItem() {
      //toggle.classList.remove('hidden')
      trigger.setAttribute('aria-expanded', 'true');
      //toggle.classList.add('visible')
      animateInMaxHeight(toggle);
      setTimeout(function () {
        toggle.classList.remove('overflow-hidden');
      }, 400);
    }

    function closeItem() {
      toggle.classList.add('overflow-hidden');
      trigger.setAttribute('aria-expanded', 'false');
      //toggle.classList.remove('visible')

      animateOutMaxHeight(toggle);
    }

    trigger.addEventListener(
      'click',
      (e) => {
        e.preventDefault();

        if (trigger.getAttribute('aria-expanded') === 'false') {
          openItem();
        } else {
          closeItem();
        }
      },
      false,
    );
  });
}
