import './global.css';
import dropdown from './scripts/dropdown';
import animateToggle from './scripts/animatetoggle';
import initExtraTable from './scripts/datatables';
import initTabs from './scripts/tabs';
import { e3_flatpickr, e3_flatpickrMobile } from './scripts/flatpickr';
import initMultiSelects from './scripts/select2';

dropdown();
animateToggle();
initExtraTable();
e3_flatpickr();
e3_flatpickrMobile();
initTabs();
initMultiSelects();

function openMenu() {
  function init() {
    const btn = document.querySelector('.extra-menu-btn');
    const hamburger = document.querySelector('.hamburger');
    const menu = document.querySelector('.extra-mobile-menu-window');

    btn.addEventListener('click', () => {
      hamburger.classList.toggle('is-active');
      menu.classList.toggle('is-open');
      document.body.classList.toggle('extra-mobile-menu-window-open');
    });

    const items = document.querySelectorAll('.primary-nav-mobile a');

    items.forEach((item) => {
      item.addEventListener('click', () => {
        btn.click();
      });
    });
  }

  const isPresent = document.getElementsByClassName('hamburger');
  if (isPresent.length > 0) {
    init();
  }
}

openMenu();
