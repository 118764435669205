import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt';

export default function initTabs() {
  const isPresent = document.querySelector("[role='tablist']");
  if (!isPresent) {
    return;
  }

  const tablist = document.querySelector("ul[role='tablist']");
  const tabs = Array.from(tablist.querySelectorAll('a'));
  const panels = document.querySelectorAll("section[role='tabpanel']");

  const LEFT_ARROW = 37;
  const RIGHT_ARROW = 39;
  const DOWN_ARROW = 40;

  tabs.forEach(function (tab, i) {
    tab.addEventListener('click', (e) => {
      e.preventDefault();
      let currentTab = tablist.querySelector('[aria-selected]');
      if (e.currentTarget !== currentTab) {
        switchTab(currentTab, e.currentTarget);
      }
    });

    tab.addEventListener('keydown', (e) => {
      const index = tabs.indexOf(e.currentTarget);

      if (e.keyCode === DOWN_ARROW) {
        e.preventDefault();
        panels[i].focus();
      }

      if (e.keyCode === LEFT_ARROW) {
        e.preventDefault();
        if (tabs[index - 1]) {
          switchTab(e.currentTarget, tabs[index - 1]);
        }
      }

      if (e.keyCode === RIGHT_ARROW) {
        e.preventDefault();
        if (tabs[index + 1]) {
          switchTab(e.currentTarget, tabs[index + 1]);
        }
      }
    });
  });

  const switchTab = (oldTab, newTab) => {
    newTab.focus();
    newTab.removeAttribute('tabindex');
    newTab.setAttribute('aria-selected', 'true');

    oldTab.removeAttribute('aria-selected');
    oldTab.setAttribute('tabindex', '-1');

    panels[tabs.indexOf(oldTab)].hidden = true;
    panels[tabs.indexOf(newTab)].hidden = false;

    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
  };
}
